/****************************************
 * 関数定義
*****************************************/
class formHoliday {
  dayAdjustment(today) {
    let yyyy = today.getFullYear() 
    let mm = ("0" + (today.getMonth() + 1)).slice(-2) 
    let dd = ("0" + today.getDate()).slice(-2) 
    let value = yyyy + '-' + mm + '-' + dd  
    return value 
  }

  async getHolidayList() {
    const Api = "https://tn-post-341508.an.r.appspot.com/"
    const token = "AiVVRa6ieuAyjBe"
    const from_date = "2025-01-01"
    const URL = Api + "?token=" + token + "&from_date=" + from_date //https://tn-post-341508.an.r.appspot.com/?token=AiVVRa6ieuAyjBe&from_date=2024-01-01
    let csvData = []

    await fetch(URL)
    .then(response => response.json())
    .then(data => {
      data.holiday.forEach(value => {
        let today = new Date(value)
        let day = this.dayAdjustment(today)
        csvData.push(day)
      })
    })

    const holidays = csvData
    const sungroveHolidayList = addHolidayList
    holidays.push(...sungroveHolidayList)
    return holidays
  }
}

const addHolidayList = ["2024-12-30", "2024-12-31", "2025-01-01", "2025-01-02", "2025-01-03"] //祝日判定手書き追加欄：記入例["2023-01-01", "2023-12-12"]

export default {
  formHoliday
}
