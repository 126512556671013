/*
  受注前回答フォーム用のデータ取得
*/

import formDataStore from "../../formDataStore"
import RequestBody from "../../requestBody.class"
import formFuncClass from "../../formFunctions.class"
import $ from 'jquery'
import moment from "moment"
import sha256 from 'crypto-js/sha256'
import formHoliday  from '../../formholiday.class'

//シートのダミーデータ
import sheetData from "./dummySheetData"

export default class {
  constructor(obj) {
    this.formTypeForRequestBody = 'juchuumaeKaitou'

    this.parentObj = obj
    this.$ = this.parentObj.parentObj.$
    this.postID = obj.postID
    this.subjectsID = obj.subjectsID
    this.params = obj.params
    this.abortController = new AbortController()
    this.isProd = this.parentObj.isProd
    this.demo = this.isProd ? "" : "Demo"
    this.key = formDataStore.state.apis[`loginKey${this.demo}`]
    const formHolidayFunc = new formHoliday.formHoliday()
    formHolidayFunc.getHolidayList()
    .then(data => {
      this.holidays = data
    })
    
    // フォーム情報
    this.thisFormParams = this.parentObj.parentObj.vueObj
    .thisFormParams
    //this.groupURL = this.thisFormParams[1] // talknoteグループURL
    //this.groupID = this.groupURL.split('feed/')[1].split('/')[0] // 

    // 各種API
    this.spreadsheetInsertApi = formDataStore.state.apis[`spreadsheet${this.demo}`]
    this.spreadsheetUpdateApi = formDataStore.state.apis[`spreadsheet${this.demo}`].replace('insert', 'update')
    this.spreadsheetGetApi = formDataStore.state.apis[`spreadsheet${this.demo}`].replace('insert', 'get')
    this.DBUpdateApi = formDataStore.state.apis[`dbUpdate${this.demo}`]

    this.requestFunc = new RequestBody.requestBody()
    this.nowTime = this.requestFunc.nowTime()
    this.createdAt = this.requestFunc.nowTime('created_at')

    // スプレッドシート用
    this.spreadData = this.requestFunc.setSpreadData(this.formTypeForRequestBody, this.isProd)
    this.spreadData.id = this.spreadData.spreadsheetID
    delete this.spreadData.spreadsheetID
    delete this.spreadData.is_prod
    
  }

  /**
   * データ取得
   *   
   */
  async getData() {

    // シートから取得
    await this.getSheetData()

    console.log(this.insertSheetData)

    if(Object.keys(this.insertSheetData).length == 0) return false

    return true

  }

   /**
   * 受注前回答で編集対象のデータをスプレッドシートから取得 
   */
  async getSheetData() {   

    this.insertSheetData = {}
/*
    this.insertSheetData = sheetData['455639_tn-post-ee1fa76f'][0]

    return this.insertSheetData
*/
    this.getSheetDataQuery = {
      "spread": this.spreadData,
      "target_lines": [
        {"column": '投稿ID', "value": this.postID},
        {"column": '削除日時', "value": ''},
      ],
    }
 
    await fetch(this.spreadsheetGetApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.getSheetDataQuery)
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.length > 0) {
        this.insertSheetDataTmp = res[0]
        console.log('シートデータ取得成功', this.insertSheetDataTmp )

        for(let key in this.insertSheetDataTmp) {
          this.insertSheetData[key] = {"id": "", "val": this.insertSheetDataTmp[key]}
        }
      } else {
        console.log('該当するシートデータがありません')
        alert('該当するシートデータがありません\nリロードします')
        //location.reload()
        return false
      }
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

    return this.insertSheetData
  }

  
   /**
   * フォームにデータ適用 
   */
  applyDataToForm() {
    const $ = this.$

    // モデル
    this.editableItems = this.parentObj.parentObj.vueObj.editableItems
    
    const notEdit = document.querySelectorAll('.js-notEditable') 
    notEdit.forEach((elm) => {
      elm.style.display = 'none'
    })

    // 案件ID
    this.subjectsID = this.insertSheetData['案件ID'].val
    this.parentObj.parentObj.addSubjectsIDBox(this.subjectsID)
    this.editableItems.subjectID = this.subjectsID
    
    // 受注形態
    document.querySelector(`[name="orders_type"][value="${this.insertSheetData['回答種別'].val}"]`).click()

    // 追客項目
    $("#settlement_rights_radio_button").val(this.insertSheetData['決済権'].val)
    $("#negotiationStatus").val(this.insertSheetData['商談進捗'].val)
    $("#issue").val(this.insertSheetData['課題'].val)
    $("#consultation_pulldown").val(this.insertSheetData['相談'].val)

    const insertRadioMapping = {
      'タイミング': 'timing',
      '費用懸念': 'cost_concern',
      '課題抽出': 'issue_extraction',
      '決済方法': 'settlement_method',
      '希望決済方法': 'wish_settlement_method',
      '再訪設定の有無': 'revisit',
    }

    Object.entries(insertRadioMapping).forEach(([key, name]) => {
      const value = this.insertSheetData[key] ? this.insertSheetData[key].val : ""
      if (value) {
        const element = document.querySelector(`[name="${name}"][value="${value}"]`)
        if (element) {
          element.click()
        } else {
          console.warn(`対応する要素が見つかりません: name="${name}", value="${value}"`)
        }
      }
    })

    if (this.insertSheetData['課題抽出'].val == "課題抽出あり") {
      $("#issue_radio_button").show()
    }

    if (this.insertSheetData['決済方法'].val == "提案済み(確定)") {
      $("#wish_settlement_method_radio_button").show()
    }

    if (this.insertSheetData['相談'].val && this.insertSheetData['相談'].val != "なし") {
      $("#confidant_pulldown").show()

      const isOtherConfidant = isOptionExists(this.insertSheetData['相談相手'].val)
      if (isOtherConfidant) {
        $("#confidant").val(this.insertSheetData['相談相手'].val)
      } else {
        $("#confidant").val("その他")
        $("#other_confidant").show()
        $("#other_confidant_name").val(this.insertSheetData['相談相手'].val)
      }
    }

    function isOptionExists(targetValue) {
      const confidantElement = document.getElementById("confidant")
      const confidantOptions = confidantElement.options

      for (let i = 0; i < confidantOptions.length; i++) {
        if (confidantOptions[i].value === targetValue) {
          return true
        }
      }

      return false
    }

    // 次回アクション日
    let nextActionDate = this.insertSheetData['次回アクション日'].val
    if(nextActionDate) {
      nextActionDate = nextActionDate.match(/[0-9]{4}.+?[0-9]{2}.+?[0-9]{2}/)[0]
      this.editableItems.next_action_date = nextActionDate.split('/').join('-')
    }

  }


  /**
   * クエリ構築
   */
  buildQuery() {
    /*DB*/
    try {
      // 案件ID
      let editedSubjectID = document.getElementById('editSubjectsID')

      editedSubjectID = editedSubjectID.value
    
      let WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ]
   
      // シート用
      this.insertSheetData['投稿日時'].val = this.createdAt

      this.insertSheetData['案件ID'].val = editedSubjectID
     
      this.insertSheetData['回答種別'].val = this.editableItems.orders_type

      if(this.editableItems.orders_type == '失注') {
        this.editableItems.next_action_date = ''
      } 

      // 曜日追加
      let nextActionDate = ''
      let next_action_date_db = ''
      if(this.editableItems.next_action_date) {
        nextActionDate = this.editableItems.next_action_date.split('-').join('/') + "（" + WeekChars[new Date(this.editableItems.next_action_date).getDay()]+"）"
        next_action_date_db = this.editableItems.next_action_date.split("（")[0].replace("/", "-").replace("/", "-")
      }
      this.insertSheetData['次回アクション日'].val = nextActionDate

      {
        let calText = this.insertSheetData['全文'].val

        calText = calText.replace(/\[[^\]]+\]/, `[${formDataStore.state.version}]`)

        calText = calText.replace(/案件ID：[^\\\n]+/, `案件ID：${this.insertSheetData['案件ID'].val}`)

        calText = calText.replace(/回答種別：[^\\\n]+/, `回答種別：${this.insertSheetData['回答種別'].val}`)

        calText = calText.replace(/次回アクション日：[^\\\n]+/, `次回アクション日：${this.insertSheetData['次回アクション日'].val}`)

        this.insertSheetData['全文'].val = calText
      }

      // 追客項目
      this.insertSheetData['決済権'].val = document.getElementById('settlement_rights_radio_button').value
      this.insertSheetData['商談進捗'].val = document.getElementById('negotiationStatus').value
      this.insertSheetData['課題'].val = document.getElementById('issue').value
      this.insertSheetData['相談'].val = document.getElementById('consultation_pulldown').value
      this.insertSheetData['再訪設定の有無'].val = document.querySelector('input[name="revisit"]:checked') ? document.querySelector('input[name="revisit"]:checked').value : ""
      this.insertSheetData['再訪設定日付'].val = this.insertSheetData['再訪設定の有無'].val == "有り" ? document.getElementById('next_action_date_tag').value : ""
      this.insertSheetData['タイミング'].val = document.querySelector('input[name="timing"]:checked') ? document.querySelector('input[name="timing"]:checked').value : ""
      this.insertSheetData['費用懸念'].val = document.querySelector('input[name="cost_concern"]:checked') ? document.querySelector('input[name="cost_concern"]:checked').value : ""
      this.insertSheetData['課題抽出'].val = document.querySelector('input[name="issue_extraction"]:checked') ? document.querySelector('input[name="issue_extraction"]:checked').value : ""
      this.insertSheetData['決済方法'].val = document.querySelector('input[name="settlement_method"]:checked') ? document.querySelector('input[name="settlement_method"]:checked').value : ""
      this.insertSheetData['希望決済方法'].val = document.querySelector('input[name="wish_settlement_method"]:checked') ? document.querySelector('input[name="wish_settlement_method"]:checked').value : ""
      this.insertSheetData['相談相手'].val = document.getElementById('confidant').value
      this.insertSheetData['相談相手'].val = this.insertSheetData['相談相手'].val == "その他" ? document.getElementById('other_confidant_name').value : this.insertSheetData['相談相手'].val

      let evaluation = ""
      if (document.getElementById('negotiationStatus').value) {
        const formFunction = new formFuncClass.formFunctions(moment, $, sha256)
        evaluation = formFunction.evaluationBranch(document.getElementById('negotiationStatus').value, this.insertSheetData['再訪設定日付'].val, this.holidays)
        this.insertSheetData['追客評価'].val = evaluation
      }

      // シート格納用にクエリ生成
      const sheetData = this.requestFunc.createBody(this.formTypeForRequestBody, this.isProd, [this.insertSheetData]) 
      const orders_type = this.editableItems.orders_type == "追客延長" ? this.editableItems.orders_type + evaluation : this.editableItems.orders_type

      // DB用
      let queryObj = [
        {
          "table_name": "order_pre_responses",
          "non_sub_query": { 
            "set": {
              "subjects_id": editedSubjectID,
              "next_action_date": next_action_date_db ? next_action_date_db : '',
            },
            "where": {
              "post_id": this.postID
            }
          },
          "sub_query": {
            "set": {
            "responses_type": "SELECT id FROM order_pre_responses_statuses WHERE name = '" + orders_type + "'"
            }
          },
          "support_data": this.params.support_data,
          "authC": this.params.authC
        }
      ]

      this.query = {
        toDBUpdate: queryObj,
        toSheetUpdate: {
          'spread': sheetData.spread,
          'target_lines': [
            {"column": "投稿ID", "value": this.postID}
          ],
          "update_cells": [
            {"column": "削除日時", "value": this.createdAt},
          ]
        },
        toSheetInsert: sheetData 
  
      }
  console.log(this.query)
      return this.query
    } catch(e) {
      alert('クエリ構築に失敗しました')
      console.log('クエリ構築に失敗しました')
      console.error(e)
    }
  }


    /**
   * 論理削除用クエリ構築
   */
  async buildDeleteQuery() {
   
    // スプレッドシート用
    this.delSheetQuery = {
      'spread': this.spreadData,
      'target_lines': [
        {"column": "投稿ID", "value": this.postID},
        {"column": "削除日時", "value": ''},
      ],
      "update_cells": [
        {"column": "削除日時", "value": this.createdAt},
      ]
    }

    this.dbDeleteQueryObj = [
      {
        "table_name": "order_pre_responses",
        "non_sub_query": { 
          "set": {
            "deleted_at": this.createdAt,
          },
          "where": {
            "post_id": this.postID
          }
        },
        "sub_query": {
        },
        "support_data": this.params.support_data,
        "authC": this.params.authC
      }
    ]

  }

  /*
    DBをアップデート
  */
  async updateDB() {
    return fetch(this.DBUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toDBUpdate)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DBアップデート失敗 Discordメッセージ確認'
      }

      console.log('DBアップデート成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  /*
    DBに挿入
  */
  async insertDB() {
    // DBなし
    return true
  }

  /*
    シートをアップデート
  */
  async updateSheet() {
    if(this.query.toSheetUpdate.length == 0) return true

    return fetch(this.spreadsheetUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toSheetUpdate)
    })
    .then((res) => res.text())
    .then((res) => {

      if(res - 0 == 99) {
        throw 'シート更新失敗 Discordメッセージ確認'
      }

      console.log('シート更新成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    シートに挿入
  */
  async insertSheet() {
    if(this.query.toSheetInsert.length == 0) return true

    return fetch(this.spreadsheetInsertApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toSheetInsert)
    })
    .then((res) => res.text())
    .then((res) => {

      if(res - 0 == 99) {
        throw 'シート追加失敗 Discordメッセージ確認'
      }

      console.log('シート追加成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    DBのデータを論理削除
  */
  async deleteDBData() {
    return fetch(this.DBUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.dbDeleteQueryObj)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DBデータ削除日時セット失敗 Discordメッセージ確認'
      }

      console.log('DBデータ削除日時セット成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  /*
    シートのデータを論理削除
  */
  async deleteSheetData() {
    return fetch(this.spreadsheetUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.delSheetQuery)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'シートデータ削除日時セット失敗 Discordメッセージ確認'
      }

      console.log('シートデータ削除日時セット成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }
}