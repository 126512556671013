/**
 * sgPostのAPIや設定など記述
 * 
 * **/


export default { 
  version: '1.3.24',
  devVersion: '1.3.24',

  domain: location.href.match(/192\.168|127\.0\.0\.1|localhost|dev/)? 'https://sg-post-dev.web.app/': 'https://sg-post.jp/',
  
  // firebaseの設定 
  firebaseConfig: {
    apiKey: "AIzaSyDCPhmQza6ixUIDo7TskCyYwbPzV78Q2m4",
    authDomain: "sg-posts.firebaseapp.com",
    projectId: "sg-posts",
    storageBucket: "sg-posts.appspot.com",
    messagingSenderId: "751644996339",
    appId: "1:751644996339:web:63c7241af593039cd7fd86"
  },

  // demoなどを利用する開発用アカウント
  testAccount: [
    'a_yamaguchi@sungrove.co.jp',
    'h-aoshika@sungrove.co.jp',
    't-hojo@sungrove.co.jp',
    'calendar@sungrove.co.jp',
    'y_watanabe@sungrove.co.jp',
    't-kokubo@sungrove.co.jp',
    'h-nakai@sungrove.co.jp',
    'k-uchida@sungrove.co.jp',
    's-lee@sungrove.co.jp',
    'y-fujikawa@sungrove.co.jp',
    't-maeda@sungrove.co.jp'
  ],

  // App EngineのAPI URL
  // dbApiURL: 'http://127.0.0.1:5000/', // ローカル用
  dbApiURLDemo: 'https://staging-db-cal-spread-api-dot-sungrove-db-dev.an.r.appspot.com/', // ステージング用
  dbApiURL: 'https://db-cal-spread-api-dot-sungrove-db-dev.an.r.appspot.com/', // 本番用
  //dbApiURL: 'http://34.134.224.76/', // インスタンスデバッグ用 
  // dbApiURL: 'https://api.sg-post.jp/', // 本番用

  // Callinのドメイン
  callinDomain: 'https://call-in08.com/',

  // slack投稿URL
  slackPOSTURL: 'https://slack.com/api/chat.postMessage',

  // slack App token
  slackToken: 'xoxb-5478590747857-5886596075479-lqsJ7ajlKwDVrpOOWUoYMK4S',

  // slack ワークスペースURL（メッセージリンク構築用）
  slackWorkSpaceURL: 'https://w1687418681-mtk234981.slack.com/archives/',

  //案件ID検索用URL
  searchSubjectsIDApiURL: 'https://agile-extension-338603.de.r.appspot.com/',

  // 読み込むスプレッドシートのURL
  getFormGroupListURL: 'https://sheets.googleapis.com/v4/spreadsheets/1uY2F98O_gNt-ruQR9Gbpf-vn4Bavxbt3T9U7uG2-sRI/values/%E5%AF%BE%E8%B1%A1%E3%83%8E%E3%83%BC%E3%83%88%E4%B8%80%E8%A6%A7?key=AIzaSyALw7hq0i4_tQcpDNLqmG1KNaRXpVdcYJ8',

  // 案件ID検索とか理由カテゴリ取得、データ投稿するAPI
  // formDataHandleDev v3.0.0
  getDataApiURL: 'https://script.google.com/macros/s/AKfycbx5PGEBM0vS_ExTo4vLhggiN2aQ7y2aR_PguYyjMOLwhK9STy_y3sWzcjmfkSVEQaPbhw/exec',

  // カレンダー未反映通知グループURL
  calendarFailureApplyURL: "g-1000002590-433025@mail.talknote.com",

  // カレンダー未反映通知 slackチャンネルID
  calendarFailureApplySlackChannelID: 'C05RA5BP34K',

  // 外部営業用 アポ獲得slackチャンネルID
  outsideSalesApGainSlackChannelID: 'C07DDSS23UJ',

  // 外部営業用 リスキャンslackチャンネルID
  outsideSalesRescanSlackChannelID: 'C07DSGZGXUH',

  // カレンダー未反映通知用メールアドレス
  calendarFailureApplyMail: "t-kokubo@sungrove.co.jp",

  // エラー通知用 slackチャンネルID
  errorPostSlackChannelID: 'C05LAN8BX1V',

  // demo 転送チャンネルID [情シス-demo-tnpost-転送1]
  demoForwardChannelID: 'C05SCNDJ10C',

  //　本番用　ログインキー
  loginKey: "5c567b0228e6aeac12d42b357670b54cfce0646097e71628e60451ebaba648863a9e09c940c799546f7c050fbca7bb2de3d72acb92aa22e313f2d7d7a91ebb09",
  //　テスト用　ログインキー
  loginKeyDemo: "3e0a3877ae0da222933fb4ebecfa2b76cd13a0604f4aefc717f21f1ecb1cb5bfc2ce1be7c015582ce880744397ac9fef2b37ad556214d455885a850ccd141ce8",

}