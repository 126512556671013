/**
  受注前回答用フォームコンポーネントv2
*/
<template>
  <Header></Header>
  <div class="l-wrapper">
    <div class="l-content">

      <Title
      :isAdmin="isAdmin"
      :groupTitle="groupTitle"
      :modeChangeBtnText="modeChangeBtnText"
      :modeChangeBtnLabel="modeChangeBtnLabel"
      :modeStatus="modeStatus"
      :modeChange="modeChange"
      :edit="edit"
      :post="post"
      ></Title>

      <Section>
        <Description>
          お疲れさまです。<br>
          <span v-html="displayName"></span>さん
        </Description>
      </Section>

      <form action="" method="get" id="form_ap" autocomplete="off">

        <Box class="form_ap_area">
          <div class="form-example">
            <div class="form-example-inner">

              <Column id="searchID" class="js-notEditable">
                <label>案件IDで案件検索</label>
                <div class="form_parts">
                  <input id="subject_id_tag" class="required" type="text" name="subject_id" data-id="" placeholder="（例）AP111111_111111" min="22" value>
                </div>
              </Column>
              <Column id="result_api" class="js-notEditable">
                <label>検索コメント：</label>
                <label id="status_comment" class="c-notice">案件IDを入力すると検索開始します。</label>
                <div class="width100 text_center">
                  <span class="wrapper4 timer-loader" id="loading_form"></span>
                </div>
                <div class="display_tate" id="result_titles">
                </div>
              </Column>
              <Column class="form_wrap js-notEditable">
                <label>案件名</label>
                <div class="form_parts">
                  <input id="subject_name_tag" class="required" type="text" name="subject_name" value>
                </div>
              </Column>
              <Column class="form_wrap js-notEditable" id="field_ap_name">
                <label>担当AP（苗字 or ビジネスネームを入力すると検索開始します）</label>
                <Column>
                  <Flex class="display_yoko">
                    <i class="chara_bottom material-icons" style="line-height: 50px;">search</i>
                    <input type="text" id="searchAPName" class="required" placeholder="苗字 or ビジネスネーム" pattern="">
                  </Flex>
                  <label class="c-choice">
                    <input type="checkbox" id="ap_retiremented"/>
                    <span>担当AP退職済み</span>
                  </label>
                </Column>
                <label class="ap_name_label" style="display: none;">候補</label>
              </Column>
              <Column class="form_wrap js-notEditable" id="field_passer_name">
                <label>パサー（苗字 or ビジネスネームを入力すると検索開始します）</label>
                <Column>
                  <Flex class="display_yoko">
                    <i class="chara_bottom material-icons" style="line-height: 50px;">search</i>
                    <input type="text" id="searchPasserName" placeholder="苗字 or ビジネスネーム" pattern="">
                  </Flex>
                </Column>
                <label class="passer_name_label" style="display: none;">候補</label>
              </Column>
              <Column class="form_wrap js-editable">
                <label>受注形態</label>
                <ul class="c-choices">
                  <li class="c-choices__item">
                    <label class="c-choice" for="radio_button_1">
                      <input id="radio_button_1" class="required" type="radio" name="orders_type" value="受注" v-model="editableItems.orders_type" />
                      <span>受注</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="radio_button_2">
                      <input id="radio_button_2" class="required" type="radio" name="orders_type" value="追客延長" v-model="editableItems.orders_type" />
                      <span>追客延長</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="radio_button_7">
                      <input id="radio_button_7" class="required" type="radio" name="orders_type" value="失注" v-model="editableItems.orders_type" />
                      <span>失注</span>
                    </label>
                  </li>
                </ul>
              </Column>
              <Column class="form_wrap_pursuit_guest js-editable" id="settlement_rights_column" style="display: none;">
                <label for="">決済権</label>
                <div class="c-select">
                  <select name="" id="settlement_rights_radio_button" class="form_select form_wrap_required">
                    <option value="" disabled selected>--決済権 選択--</option>
                    <option value="決定権あり・決裁権あり">決定権あり・決裁権あり</option>
                    <option value="決定権のみ">決定権のみ</option>
                    <option value="決定権なし・決裁権なし">決定権なし・決裁権なし</option>
                    <option value="連絡不通・第三者のみ接続">連絡不通・第三者のみ接続</option>
                  </select>
                </div>
              </Column>
              <Column class="form_wrap_pursuit_guest js-editable settlement_rights_display" id="timing_radio_button" style="display: none;">
                <label>タイミング</label>
                <ul class="c-choices">
                  <li class="c-choices__item">
                    <label class="c-choice" for="timing_button_1">
                      <input id="timing_button_1" class="form_wrap_required settlement_rights_required" type="radio" name="timing" value="タイミング"/>
                      <span>タイミング</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="timing_button_2">
                      <input id="timing_button_2" class="form_wrap_required settlement_rights_required" type="radio" name="timing" value="タイミングじゃない"/>
                      <span>タイミングじゃない</span>
                    </label>
                  </li>
                </ul>
              </Column>
              <Column class="form_wrap_pursuit_guest js-editable settlement_rights_display" id="progress_negotiations_radio_button" style="display: none;">
                <label for="">商談進捗</label>
                <div class="c-select">
                  <select name="" id="negotiationStatus" class="form_select form_wrap_required settlement_rights_required">
                    <option value="" disabled selected>--商談進捗 選択--</option>
                    <option value="受注意思まで">受注意思まで</option>
                    <option value="提案まで">提案まで</option>
                    <option value="興味付け">興味付け</option>
                    <option value="ヒアリングまで">ヒアリングまで</option>
                    <option value="リーガルチェック">リーガルチェック</option>
                  </select>
                </div>
              </Column>
              <Column class="form_wrap_pursuit_guest js-editable settlement_rights_display" id="cost_concern_radio_button" style="display: none;">
                <label>費用懸念</label>
                <ul class="c-choices">
                  <li class="c-choices__item">
                    <label class="c-choice" for="cost_concern_button_1">
                      <input id="cost_concern_button_1" class="form_wrap_required settlement_rights_required" type="radio" name="cost_concern" value="ある"/>
                      <span>ある</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="cost_concern_button_2">
                      <input id="cost_concern_button_2" class="form_wrap_required settlement_rights_required" type="radio" name="cost_concern" value="ない"/>
                      <span>ない</span>
                    </label>
                  </li>
                </ul>
              </Column>
              <Column class="form_wrap_pursuit_guest js-editable settlement_rights_display" id="issue_extraction_radio_button" style="display: none;">
                <label>課題抽出</label>
                <ul class="c-choices">
                  <li class="c-choices__item">
                    <label class="c-choice" for="issue_extraction_button_1">
                      <input id="issue_extraction_button_1" class="form_wrap_required settlement_rights_required" type="radio" name="issue_extraction" value="提案できていない、再商談"/>
                      <span>提案できていない、再商談</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="issue_extraction_button_2">
                      <input id="issue_extraction_button_2" class="form_wrap_required settlement_rights_required" type="radio" name="issue_extraction" value="課題抽出あり"/>
                      <span>課題抽出あり</span>
                    </label>
                  </li>
                </ul>
              </Column>
              <Column class="form_wrap_pursuit_guest conditional_question js-editable settlement_rights_display" id="issue_radio_button" style="display: none;">
                <label for="">課題</label>
                <div class="c-select">
                  <select name="" id="issue" class="form_select issue_required settlement_rights_required">
                    <option value="" disabled selected>--課題 選択--</option>
                    <option value="人出不足">人出不足</option>
                    <option value="世代交代">世代交代</option>
                    <option value="コスト削減">コスト削減</option>
                    <option value="集客キャパあり">集客キャパあり</option>
                    <option value="ノウハウがない">ノウハウがない</option>
                    <option value="求人.集客の質">求人.集客の質</option>
                  </select>
                </div>
              </Column>
              <Column class="form_wrap_pursuit_guest js-editable settlement_rights_display" id="settlement_method_radio_button" style="display: none;">
                <label>決済方法</label>
                <ul class="c-choices">
                  <li class="c-choices__item">
                    <label class="c-choice" for="settlement_method_button_1">
                      <input id="settlement_method_button_1" class="form_wrap_require settlement_rights_requiredd" type="radio" name="settlement_method" value="提案済み(未確定)"/>
                      <span>提案済み(未確定)</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="settlement_method_button_2">
                      <input id="settlement_method_button_2" class="form_wrap_required settlement_rights_required" type="radio" name="settlement_method" value="提案済み(確定)"/>
                      <span>提案済み(確定)</span>
                    </label>
                  </li>
                </ul>
              </Column>
              <Column class="form_wrap_pursuit_guest conditional_question js-editable settlement_rights_display" id="wish_settlement_method_radio_button" style="display: none;">
                <label>希望決済方法</label>
                <ul class="c-choices">
                  <li class="c-choices__item">
                    <label class="c-choice" for="wish_settlement_method_button_1">
                      <input id="wish_settlement_method_button_1" class="wish_settlement_method_required settlement_rights_required" type="radio" name="wish_settlement_method" value="分割希望"/>
                      <span>分割希望</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="wish_settlement_method_button_2">
                      <input id="wish_settlement_method_button_2" class="wish_settlement_method_required settlement_rights_required" type="radio" name="wish_settlement_method" value="一括希望"/>
                      <span>一括希望</span>
                    </label>
                  </li>
                </ul>
              </Column>
              <Column class="form_wrap_pursuit_guest js-editable settlement_rights_display" style="display: none;">
                <label for="">相談</label>
                <div class="c-select">
                  <select name="" id="consultation_pulldown" class="form_select form_wrap_required settlement_rights_required">
                    <option value="" disabled selected>--相談 選択--</option>
                    <option value="なし">なし</option>
                    <option value="社内相談（費用のみ）">社内相談（費用のみ）</option>
                    <option value="社内相談（費用+費用対）">社内相談（費用+費用対）</option>
                    <option value="社外相談（費用のみ）">社外相談（費用のみ）</option>
                    <option value="社外相談（費用+費用対）">社外相談（費用+費用対）</option>
                  </select>
                </div>
              </Column>
              <Column class="form_wrap_pursuit_guest conditional_question js-editable settlement_rights_display" id="confidant_pulldown" style="display: none;">
                <label for="">相談相手</label>
                <div class="c-select">
                  <select name="" id="confidant" class="form_select confidant_pulldown_required settlement_rights_required">
                    <option value="" disabled selected>--相談相手 選択--</option>
                    <option value="代表">代表</option>
                    <option value="役員会">役員会</option>
                    <option value="親会社・グループ会社">親会社・グループ会社</option>
                    <option value="WEB担当">WEB担当</option>
                    <option value="経理">経理</option>
                    <option value="親族">親族</option>
                    <option value="出資者">出資者</option>
                    <option value="従業員">従業員</option>
                    <option value="その他">その他</option>
                  </select>
                </div>
              </Column>
              <Column class="form_wrap_pursuit_guest conditional_question js-editable settlement_rights_display" id="other_confidant" style="display: none;">
                <div class="form_parts">
                  <input type="text" id="other_confidant_name" class="other_confidant_required" placeholder="その他相談相手区分を入力">
                </div>
              </Column>
              <Column class="form_wrap_pursuit_guest js-editable settlement_rights_display" id="revisit_radio_button" style="display: none;">
                <label>再訪設定の有無</label>
                <ul class="c-choices">
                  <li class="c-choices__item">
                    <label class="c-choice" for="revisit_button_1">
                      <input id="revisit_button_1" class="form_wrap_required revisit_radio_button_required" type="radio" name="revisit" value="有り"/>
                      <span>有り</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="revisit_button_2">
                      <input id="revisit_button_2" class="form_wrap_required revisit_radio_button_required" type="radio" name="revisit" value="無し"/>
                      <span>無し</span>
                    </label>
                  </li>
                </ul>
              </Column>
              <Column class="form_wrap js-editable">
                <label>次回アクション日</label>
                <label id="display_revisit_annotation" style="display: none;">※再訪設定日として入れてください</label>
                <label id="next_action_date_tag_check"></label>
                <Flex id="next_action_date" class="display_yoko">
                  <div class="form_parts">
                    <input type="date" id="next_action_date_tag" class="required form_txt" min="" name="next_action_date" placeholder="日付指定" v-model="editableItems.next_action_date">
                  </div>
                </Flex>
              </Column>
              <Column class="form_wrap js-notEditable">
                <label>理由</label>
                <div class="form_parts">
                  <textarea id="reasons_tag" class="required" name="reasons"></textarea>
                </div>
              </Column>
              <Column class="form_wrap js-notEditable">
                <label>備考</label>
                <div class="form_parts">
                  <textarea id="other_things_tag" name="other_things"></textarea>
                </div>
              </Column>
            </div>
          </div>
        </Box>

        <div class="pd2 text_center form_wrap" id="filedBtn">
          <Button :tag="'a'" class="waves-effect waves-light btn disabled" id="btn">slackへ送信</Button>
        </div>

      </form>

    </div>
  </div>
  <Loading :class="{ '-active': isLoading }" :loaderText="loaderText"></Loading>
  <Complete v-if="isComplete" :groupTitle="groupTitle" :groupURL="groupURL" :searchURL="searchURL" :message="message" :completeTitle="completeTitle"></Complete>
</template>


<script>
  import { defineComponent } from 'vue'
  import moment from "moment"
  import $ from 'jquery'
  import "jquery-ui"
  import sha256 from 'crypto-js/sha256'
  import formDataStore from "../../assets/js/formDataStore"
  import juchuumaeKaitouClass from "../../assets/js/juchuumaeKaitou.class.js"
  import formFuncClass from "../../assets/js/formFunctions.class.js"

  // コンポーネントを読み込む
  import Header from '../organisms/header.vue';
  import Title from '../organisms/title.vue';
  import Loading from '../organisms/loading.vue';
  import Complete from '../complete.vue';

  import Variables from '../molecules/variables.vue';
  import Choices from '../molecules/choices.vue';
  import Box from '../molecules/box.vue';
  import Section from '../molecules/section.vue';
  import Column from '../molecules/column.vue';
  import Group from '../molecules/group.vue';
  import Flex from '../molecules/flex.vue';
  import Block from '../molecules/block.vue';

  import Description from '../atoms/description.vue';
  import Label from '../atoms/label.vue';
  import Input from '../atoms/input.vue';
  import Select from '../atoms/select.vue';
  import Textarea from '../atoms/textarea.vue';
  import Button from '../atoms/button.vue';
  import IconAdd from '../atoms/icon-add.vue';
  import IconRemove from '../atoms/icon-remove.vue';

  export default defineComponent({
    name: 'formJuchuumaekaitou',

    components: {
      Header,
      Title,
      Loading,
      Complete,

      Variables,
      Choices,
      Box,
      Section,
      Column,
      Group,
      Flex,
      Block,

      Description,
      Label,
      Input,
      Select,
      Textarea,
      Button,
      IconAdd,
      IconRemove,
    },

    data() {
      return {
        isLoading: false,
        isComplete: false,
        isBody: true,
        loaderText: '',
        displayName: '',
        groupTitle: '',
        searchURL: '',
        message: '',
        completeTitle: '',
        modeChangeBtnText: '',
        modeChangeBtnLabel: '',
        modeStatus: '',
        
        isAdmin: false,
        modeChangeBtnText: '',
        modeChangeBtnLabel: '',
        modeStatus: '',
        modeChange: () => {},
        edit: true,
        post: false,

        editableItems: {}
      }
    },

    methods: {
      init() {
        console.log('フォーム初期化開始')
        // このフォームの名前とかtalknoteの投稿用アドレスなどの情報格納
        this.thisFormParams = this.store.state.groupList.filter((obj) => obj[0].trim() == this.$route.params.type.trim())[0]

        // タイトル表示
        this.groupTitle = this.thisFormParams[0]

        // フォームが本番かdemoか
        this.isProd = this.thisFormParams[0].indexOf('demo_') == -1

        // ユーザー名表示
        // メアドとかもthis.store.state.userDataに入ってる
        this.displayName = this.store.state.userData.displayName

        // 投稿先のslackチャンネル情報
        this.slackChannel = {
          id: this.thisFormParams[6],
          userID: this.store.state.userData.slackUserID,
          name: this.thisFormParams[7],
          postURL: this.store.state.apis.slackPOSTURL,
          workSpaceURL: this.store.state.apis.slackWorkSpaceURL,
          token: this.store.state.apis.slackToken,
          iconURL: this.store.state.userData.emoji,
          forwardID01: this.thisFormParams[8],
          forwardID02: this.thisFormParams[9],
          errorPostID: this.store.state.apis.errorPostSlackChannelID
        }

        document.documentElement.scrollTop = 0

        // 投稿先のtalknoteノートURL
        // console.log('投稿先のtalknoteノートURL', this.thisFormParams[1])

        // talknote投稿用アドレス
        // console.log('talknote投稿用アドレス', this.thisFormParams[2])

        // 表示するフォームのタイプ
        // console.log('表示するフォームのタイプ', this.thisFormParams[3])

        // 外部にあるフォームで使う汎用関数インスタンス化
        const func = new formFuncClass.formFunctions(moment, $, sha256, this)
        new juchuumaeKaitouClass.juchuumaeKaitou(this, $, func, moment)

        console.log('フォーム初期化完了')
      },

      viewLoader(loaderText = '') {
        if(
            (!this.loaderText && !loaderText) ||
            (this.isLoading && loaderText == '')
          ) {
          this.isLoading = false
          this.loaderText = ''
          return 
        }
        this.isLoading = true
        this.loaderText = loaderText
        console.log('ローディング表示')
      },

      viewComplete(param) {
        this.groupTitle = param.groupTitle;
        this.groupURL = param.groupURL;
        this.searchURL = param.searchURL;
        this.message = param.message;
        this.completeTitle = param.completeTitle? param.completeTitle: '投稿完了';
        this.isBody = false;
        this.isComplete = true;
        console.log('完了画面表示')
      },
    },

    mounted() {

      this.store = formDataStore

      if(this.store.state.groupList.length == 0) {

        // このフォームに直接飛んできたときにフォーム情報取得が完了してないことがあるので同期待ち
        this.store.watch(
          (state, getters) => getters.groupList,
          (newValue, oldValue) => {
            console.log('ページ固有のデータ取得');
            //初期化
            this.init()
          }
        )
      } else {
        //初期化
        this.init()
      }
    },

    setup(_, context) {
      return {}
    }
  });
</script>
