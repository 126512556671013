class juchuumaeKaitou {
  constructor(vueObj, $, func, moment) {

    try {
      // Vueインスタンスを取得
      this.vueObj = vueObj

      this.store = vueObj.store

      this.isProd = this.vueObj.isProd

      // jQueryインスタンスを取得
      this.$ = $

      // フォーム関連関数を取得
      this.func = func

      // momentインスタンスを取得
      this.moment = moment

      // propsを取得
      //this.props = this.vueObj.getProps()
      this.thisFormParams = vueObj.thisFormParams

      // 案件ID検索とか理由カテゴリ取得するAPI
      this.getDataApiURL = this.store.state.apis.getDataApiURL

      // 投稿先slackチャンネル情報
      this.slackChannel = vueObj.slackChannel
      this.demo = this.isProd ? "" : "Demo"
      this.key = this.store.state.apis[`loginKey${this.demo}`]
      
      // slack チャンネル URL
      this.groupURL = this.slackChannel.workSpaceURL + this.slackChannel.id 

      // フォームタイトル
      this.groupTitle = this.thisFormParams[0].replace(/\n/gi,"").replace(/\t/gi,"")

      // DBを操作するAPI
      this.dbApiURL = this.store.state.apis[`dbApiURL${this.demo}`]

      // talknoteの投稿先アドレス
      //this.talknoteGroupAddress = this.thisFormParams[2].replace(/\n/gi,"").replace(/\t/gi,"")

      // 変数定義
      this.CHECKED_COLOR = "teal lighten-2"
      this.NOW_URL = location.href
      this.ReceiveOrderObj = {
        type: "JuchuumaeKaitouPost", 
        user_id: this.store.state.userData.userID, 
        group_id: this.slackChannel.id , 
        group_name: this.groupTitle, 
        data: {
          orders_type: "",
          subject_id: "", 
          subject_name: "", 
          next_action_date: "", 
          reasons: "", 
          other_things: "",  
          post_id: "", 
          post_url: "", 
          post_time: ""
        }
      }

      this.orders_type_tag = $('[name="orders_type"]')
      this.subject_id_tag = $("#subject_id_tag")
      this.subject_name_tag = $("#subject_name_tag")
      this.next_action_date_tag = $("#next_action_date_tag")
      this.reasons_tag = $("#reasons_tag")
      this.other_things_tag = $("#other_things_tag")
      this.$btn = $("#btn")

      this.searchFlagAP = true
      this.searchFlagPasser = true

      // 今日の日付を取得
      this.today = moment()
      this.today = this.today.format('YYYY/MM/DD HH:mm:ss')
      this.holidays = ""

      // 初期化
      this.init()

    } catch(e) {
      console.error(e.stack, e.message)

      let text = []

      if(!this.vueObj) {
        text.push('Vueインスタンスが取得できませんでした。')
      }

      if(!this.$) {
        text.push('jQueryが取得できませんでした。')
      }

      if(text.length == 0) {
        alert('予期せぬエラーが発生しました。')
      } else {
        alert(text.join('\n'))
      }

      return false
    }

  }

  init() {
    const $ = this.$

    $('.autoG,.gotAP').css('display', 'none')

    this.actionInit()
  }

  actionInit() {
    const that = this
    const $ = this.$
    const func = this.func
    const moment = this.moment
    const officeData = new OfficeDatas()
    const formHolidayFunc = new formHoliday.formHoliday()
    formHolidayFunc.getHolidayList()
    .then(data => {
      this.holidays = data
    })

    /****************************************
     * アクション定義
    *****************************************/

    /* 
      ▼案件IDフォーマット
      AP[グループID]_[投稿ミリ秒]
      TW[グループID]_[投稿ミリ秒]
      OT[グループID]_[投稿ミリ秒]
      SL[グループID][T or O]_[投稿ミリ秒]
      └ 投稿者の所属エリアにより、T(Tokyo)orO(Osaka)で決まる
    */

    // 案件ID検索
    that.subject_id_tag.off('input.search').on('input.search', function(e) {
      let _this = $(this)
      let formWrap = $(".form_wrap")

      const subject_id = _this.val()
      let textCount = 22

      if(subject_id.indexOf('_') == 13) {
        textCount = 26
      }

      let before = _this.data('subject_id')

      if(subject_id.indexOf('sungrove') == 0) {
        textCount = 11
      } else {
        formWrap.slideUp()
        $(".form_wrap_pursuit_guest").slideUp()
      }

      if(subject_id.length < textCount || (before && before != subject_id) || e.originalEvent.inputType.indexOf("delete") == 0) {
        $('#status_comment').text('')
        _this.data('subject_id', '')
        before = ""

        if(formWrap.eq(0).css('display') == 'block') {
          that.formClearFlag = 1
          _this.trigger('input.clear')
          _this.val(subject_id)
        }

        return false
      }

      const result = subject_id.match(/(^[A-Z]{2}[0-9A-Z]{11}?_[0-9]{13}$|^[A-Z]{2}[0-9]{6}[A-Z]?_[0-9]{13}$|^sungrove[123])/)
      
      if(!result) {
        if(subject_id.indexOf('sungrove') > -1) {
          _this.val(subject_id)
        } 

        //alert('案件IDを正しく入力してください。')

        //$('#SubjectId').val('')

        return false
      }
      
      // 入力されたら
      if($('#status_comment').text().indexOf('検索中') == -1) {

        if(subject_id.startsWith('sungrove') == false) {      

          const reg1 = /^[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]|[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]$/
          const reg2 = /[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]/g
          _this.attr('readonly', true)

          if(reg1.test(subject_id)) subject_id = subject_id.replace(reg2, "")

          that.$btn.addClass('disabled').removeClass('pulse')
          $('#status_comment').text('検索中...🔎 少々お待ちください😣').css('color', '#757575')
          // Loading表示
          $('#loading_form').css('display', 'block')

          // 案件ID検索APIに投げる
          console.log(new Date())

          // 案件ID検索APIに投げるGAS API URL
          //let searchApiURL = that.getDataApiURL + '?type=searchSubjectId&SubjectId=' + subject_id

          let searchApiURL = that.store.state.apis.searchSubjectsIDApiURL + subject_id

          that.formClearFlag = 1
          //_this.trigger('input.clear')
          
          _this.val(subject_id)

          // Loading表示
          that.vueObj.viewLoader('検索中')

          // 案件ID検索開始
          fetch(searchApiURL)
          .then(response => response.json())
          .then(response => {

            // Loading非表示
            that.vueObj.viewLoader()
            let responsePrevious = response.filter(item => item.description && item.description.indexOf("掘り起こし元ID：" + subject_id) !== -1)
            let newID = ""
            if (responsePrevious.length > 0) {
              newID = responsePrevious[0].description.split("案件ID：")[1].split("\n")[0]
              newID = "掘り起こし後のID候補：" + newID
              alert("掘り起こし済みIDです！ IDを確認してください！")
              response = []
            } else {
              response = response.filter(item => item.description && item.description.indexOf("案件ID：" + subject_id) !== -1)
            }
            console.log(response)

            $('select').css('display', 'block')
            _this.attr({readonly: false, 'data-subject_id': subject_id})
            $('#loading_form').css('display', 'none')
            $('#status_comment').text('')
            $('#ComName').val('')
            
            if(response.length > 0) {
              formWrap.slideDown()

              $('#status_comment').text('対象の案件を見つけました👀')

              let resTitle = response[0].title
              let resCalId = response[0].cal_id
              let exchangeEle = {}

              let searchAPName = resTitle.match(/【([^】]*)】/)
              searchAPName = searchAPName ? searchAPName[1] : ""
              searchAPName = searchAPName.replace('🔰', '')
              $('#searchAPName').val(searchAPName)
              $('#searchAPName').trigger('input')

              const regSaihou = /\[.*再.*\]/
              const errorText = func.titlePattern(resTitle, regSaihou)
              if (errorText) {
                $('#status_comment').html(errorText).css({'color': 'red', 'font-size': '16px'})
              }

              const isIncludeSaihouCal = officeData.checkIncludeSaihouCal(resCalId)
              const isRescanCal = officeData.checkRescanCal(resCalId)

              if(resTitle.indexOf('///*') !== -1 || resTitle.indexOf('*///') !== -1) {
                exchangeEle = func.v2Title(response[0])
              } else if (isIncludeSaihouCal || (isRescanCal && regSaihou.test(resTitle))) {
                exchangeEle = func.saihou(response[0])
              } else if(resTitle.indexOf('///') !== -1) {
                exchangeEle = func.v1Title(response[0])
              }

              // 項目自動入力
              that.subject_name_tag.val(exchangeEle.com_name).attr('readonly', true)

              that.ReceiveOrderObj.data.subject_name = exchangeEle.com_name

            } else {
              _this.attr({readonly: false})
              $('#status_comment').html('もう一度IDを確認して検索してください。<br>' + newID).css('color', '#e91e63')
            }
          })
          .catch((e) => {
            console.error(e.stack, e.message)
          })

          return true

        } else if(subject_id.match(/^sungrove12?3?/)) {
          $('select').css('display', 'block')
          formWrap.slideDown()
          that.subject_name_tag.attr('readonly', false)
        
          $('#status_comment').text('案件IDがない案件の為、手動入力モードに切り替えました📝').css('color', '#e91e63')
        }
      } 
    })

    // [案件IDクリア]
    that.subject_id_tag.off('input.clear').on('input.clear', function() {
      const _this = $(this)
      const subject_id = _this.val()

      if(subject_id == "" || that.formClearFlag == 1) {
        that.formClearFlag = 0
        $('.form_wrap').slideUp()
        $(".form_wrap_pursuit_guest").slideUp()
        $('#status_comment').text('案件IDを入力すると検索開始します').css('color', '#757575')

        func.clearInput($('input[type="text"], input[type="Number"], input[type="url"], .form_txt, textarea'))   
        func.checkOut($('.form_check, .required'))
        func.clearAry(that.ReceiveOrderObj.data)
        _this.val(subject_id)
      }
    })
   
    // カレンダー 
    /*
    that.next_action_date_tag.off('click.next_action_date_tag').on('click.next_action_date_tag', function() {
      let _this = $(this)
      let date = _this.val()
      let current = date && date.match(/[0-9]{4}\/[0-9]{2}\/[0-9]{2}/)? date: moment().toDate()
      _this.datepicker("dialog", current, function(date) {
        _this.val(date)
      }, {
        dateFormat : 'yy/mm/dd',
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
      })
    })*/


    //[必須項目判定]
    $('#form_ap').off('change.checkRequired input.checkRequired click.checkRequired mousemove.checkRequired').on('change.checkRequired input.checkRequired click.checkRequired mousemove.checkRequired', function() {
      let resultRequiredArray = func.checkRequired()
      let required_NG = 0
      let check_required = 0

      for(let key in resultRequiredArray) {
        resultRequiredArray[key] == 0 ? required_NG = 1 : check_required = 1
      }

      if(check_required == 1 && required_NG == 0) {
        that.$btn.removeClass('disabled').addClass('pulse')
      }

    })

    //受注結果
    that.orders_type_tag.off('change.orders_type').on('change.orders_type', function() {
      let $next_action_date = $("#next_action_date")
      that.ReceiveOrderObj.data.orders_type = $("input[name='orders_type']:checked").val();

      if (that.ReceiveOrderObj.data.orders_type.match(/受注|失注/)) {
        func.removeRequiredOp("next_action_date_tag")
        document.getElementById("next_action_date_tag").style.backgroundColor = ''
        
        if(that.ReceiveOrderObj.data.orders_type == "失注") {
          $next_action_date.parent().hide()  
        } else {
          $next_action_date.parent().show()
        }
        
        //次回アクション日の値を空にする
        that.ReceiveOrderObj.data.next_action_date = ""
        that.next_action_date_tag.val('')
        resetPursuitGuest()

      } else if (that.ReceiveOrderObj.data.orders_type.match(/追客延長/)) {
        resetPursuitGuest()
        $next_action_date.parent().show()
        that.next_action_date_tag.addClass('required')
        $(".form_wrap_pursuit_guest").show()
        $(".form_wrap_required").addClass("required")
        $(".conditional_question").hide()

      } else {
        $next_action_date.parent().show()
        that.next_action_date_tag.addClass('required')
        resetPursuitGuest()
      }
    })

    function resetPursuitGuest() {
      $(".form_wrap_pursuit_guest").hide()
      $(".form_wrap_required, .issue_required, .wish_settlement_method_required, .confidant_pulldown_required, .other_confidant_required").removeClass("required")
      $("[name='timing']:checked").prop("checked", false)

      const formContainers = document.querySelectorAll('.form_wrap_pursuit_guest')
      formContainers.forEach(container => {
        const selects = container.querySelectorAll('select')
        selects.forEach(select => {
          select.selectedIndex = 0
        })
        const radioButtons = container.querySelectorAll('input[type="radio"]')
        radioButtons.forEach(radio => {
          radio.checked = false
        })
      })
    }

    //[商談時刻制限(遡り防止)]
    $('#next_action_date_tag').change(function() {
      let formated_today = moment().format('YYYY-MM-DD')
      $('#next_action_date_tag_check').text("")
      
      if ($('#next_action_date_tag').val() < formated_today) {
        //[形式判定：商談日時]
        $('#next_action_date_tag_check').text('※ 本日以降の日付を入力してください').css('color', '#e91e63')
        $('#next_action_date_tag').val("")
      }
      
    })

    $('[name="issue_extraction"]').change(function() {
      if (this.value == "課題抽出あり") {
        $('#issue_radio_button').show()
        $('.issue_required').addClass('required')
      } else {
        $('#issue_radio_button').hide()
        $('.issue_required').removeClass('required')
      }
    })

    $('[name="settlement_method"]').change(function() {
      if (this.value == "提案済み(確定)") {
        $('#wish_settlement_method_radio_button').show()
        $('.wish_settlement_method_required').addClass('required')
      } else {
        $('#wish_settlement_method_radio_button').hide()
        $('.wish_settlement_method_required').removeClass('required')
      }
    })

    $('#consultation_pulldown').change(function() {
      if (this.value == "なし") {
        $('#confidant, #other_confidant_name').val("")
        $('#confidant_pulldown, #other_confidant').hide()
        $('.confidant_pulldown_required, .other_confidant_required').removeClass('required')
      } else {
        $('#confidant_pulldown').show()
        $('.confidant_pulldown_required').addClass('required')
      }
    })

    $('#confidant').change(function() {
      if (this.value == "その他") {
        $('#other_confidant').show()
        $('.other_confidant_required').addClass('required')
      } else {
        $('#other_confidant').hide()
        $('.other_confidant_required').removeClass('required')
      }
    })

    $('[name="revisit"]').change(function() {
      if (this.value == "有り") {
        $('#display_revisit_annotation').show()
      } else {
        $('#display_revisit_annotation').hide()
      }
    })

    let is_break_contact = false
    $('#settlement_rights_radio_button').change(function() {
      const selectValue = this.value
      if (selectValue == "連絡不通・第三者のみ接続") {
        resetPursuitGuest()
        $("#settlement_rights_column").show()
        is_break_contact = true
      } else {
        if (is_break_contact) {
          resetPursuitGuest()
          $(".form_wrap_pursuit_guest").show()
          $(".form_wrap_required").addClass("required")
          $(".conditional_question").hide()
          is_break_contact = false
        }
      }
      $("#settlement_rights_radio_button").val(selectValue)
    })

    function searchUser(userName, htmlID, className) {
      if (className == 'ap_name') {
        that.searchFlagAP = true
      } else if (className == 'passer_name') {
        that.searchFlagPasser = true
      }
      
      $('.msg_loading, .' + className).remove()
      $('<label class="pd5_rl msg_loading">検索中(_ _).｡o○</label>').appendTo("#" + htmlID)

      if(userName == "") {
        $('.msg_loading, .' + className).remove()
        $('.' + className + '_label').hide()
        return false
      }

      $('.' + className + '_label').show()
      that.abortController = new AbortController()

      const params = {
        authC: {
          password: "W!z|a~.x.T~Tzca8CBCm",
          mail: that.userEmail
        },
        main_data: {
          tables:["users"],
          query: `SELECT * FROM users WHERE (first_name LIKE "%${userName}%" OR business_name LIKE "%${userName}%") AND retirement_date IS NULL AND deleted_at IS NULL`
        },
        support_data: {
          is_prod: that.isProd
        }
      }

      const color = '#e91e63'
      const searchApiURL = that.dbApiURL + 'db/select'

      that.vueObj.viewLoader('同行者検索中')

      fetch(searchApiURL, {
        signal: that.abortController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Cal-St-Api': that.key
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(response => {
        const mes = $('.msg_loading')
        let text = ''
        const res = typeof response == 'String'? response - 0 : response 

        switch(res) {
          case 0: {
            if(response[0].user_id !== '#N/A') {
              mes.remove()
              formFunction.createUserList(response, htmlID, className)
            } else {
              text = '検索できませんでした。検索ワードをもう一度確認してから検索してください。'
            }
            break
          }
          case 1: {
            text = 'アクセスが許可されていません。'
            break
          }
          case 2: {
            text = 'リクエストデータがありません。'
            break
          }
          case 3: {
            text = '認証が通りませんでした。'
            break
          }
          case 4: {
            text = '検索結果が50件を越えています。検索結果を絞るために文字を追加してください。'
            break
          }
          case 99: {
            text = '何らかのエラーが発生しました。'
            break
          }
        }

        if(text) {
          mes.text(text).css('color', color)
          alert(text)
        }

        if(response[0].user_id !== '#N/A') {
          mes.remove()
          formFunction.createUserList(response, htmlID, className)
        }

        that.vueObj.viewLoader()

        return response

      })
      .catch(error => {
        console.log({error})
        that.vueObj.viewLoader()
        if(error.name != "AbortError") {
          $('.msg_loading').text('エラー').css('color', color)
          if (className == 'ap_name') {
            that.searchFlagAP = false
          } else if (className == 'passer_name') {
            that.searchFlagPasser = false
          }
        }
      })
    }

    //担当AP検索
    $('#searchAPName').off('input.searchAP').on('input.searchAP', function(e) {
      $('#ap_retiremented').prop('checked', false)
      const htmlID = "field_ap_name"
      const className = "ap_name"
      let userName = $(this).val()
      if(that.intervalObj) {
        clearInterval(that.intervalObj)
        if(that.abortController && typeof that.abortController.abort == 'function') that.abortController.abort()
        that.abortController = new AbortController()
      }

      that.intervalObj = setTimeout(() => {
        searchUser(userName, htmlID, className)
      }, 700)
    })

    //パサー検索
    $('#searchPasserName').off('input.searchPasser').on('input.searchPasser', function(e) {
      const htmlID = "field_passer_name"
      const className = "passer_name"
      let userName = $(this).val()
      if(that.intervalObj) {
        clearInterval(that.intervalObj)
        if(that.abortController && typeof that.abortController.abort == 'function') that.abortController.abort()
        that.abortController = new AbortController()
      }

      that.intervalObj = setTimeout(() => {
        searchUser(userName, htmlID, className)
      }, 700)
    })

    //担当AP退職済み処理
    $('#ap_retiremented').change(function() {
      if ($(this).prop('checked')) {
        $('#searchAPName').val('').removeClass('required').css('background-color', '')
        $('.msg_loading, .ap_name').remove()
        $('.ap_name_label').hide()
      } else {
        $('#searchAPName').addClass('required').css('background-color', 'rgb(255, 205, 210)')
      }
    })

     //送信用メッセ
    that.$btn.off('click.send').on('click.send', function() {
      if (!that.searchFlagAP) {
        alert("担当AP名でエラーが出ています！\n入力内容を確認してください\n担当APが退職済みの場合は退職済みのチェックを入れてください")
        return
      } else if (!that.searchFlagPasser) {
        alert("パサー名でエラーが出ています！\n入力内容を確認してください")
        return
      }

      if(that.$btn.hasClass('pulse')) {
        moment.locale("ja")
        that.ReceiveOrderObj.data.subject_id = that.subject_id_tag.val()
        that.ReceiveOrderObj.data.subject_name = func.textEscape(that.subject_name_tag.val())
        that.ReceiveOrderObj.data.next_action_date = that.next_action_date_tag.val() ? moment(that.next_action_date_tag.val()).format("YYYY/MM/DD （ddd）"): ''
        that.ReceiveOrderObj.data.reasons = func.textEscape(that.reasons_tag.val())
        that.ReceiveOrderObj.data.other_things = func.textEscape(that.other_things_tag.val())
        that.ReceiveOrderObj.data.post_time = that.today
        const selectedAp = document.querySelector('input[name="ap_name"]:checked')
        const selectedApData = selectedAp ? selectedAp.getAttribute('data-user') : ""
        const parsedAPData = selectedApData ? JSON.parse(selectedApData) : ""
        const apSlackId = parsedAPData ? "\n担当AP：<@" + parsedAPData.slack_id + ">" : ""
        const retiremented = $('#ap_retiremented').prop('checked') ? "\n担当AP：" : ""
        const selectedPasser = document.querySelector('input[name="passer_name"]:checked')
        const selectedPasserData = selectedPasser ? selectedPasser.getAttribute('data-user') : ""
        const parsedPasserData = selectedPasserData ? JSON.parse(selectedPasserData) : ""
        const passerSlackId = parsedPasserData ? "\nパサー：<@" + parsedPasserData.slack_id + ">" : ""

        let evaluation = ""
        let isRevisit = ""
        let isRevisitText = ""
        let revisitDay = ""
        let revisitDayText = ""
        let confidant = ""

        if($("input[name='orders_type']:checked").val() == "追客延長"){
          const negotiationStatus = $('#negotiationStatus').val()
          isRevisit = $("[name='revisit']:checked").val()
          isRevisitText = "\n再訪設定の有無：" + isRevisit
          if (isRevisit == "有り") {
            revisitDay = $('#next_action_date_tag').val()
            revisitDayText = "\n再訪設定日時：" + revisitDay
          }
          evaluation = formFunction.evaluationBranch(negotiationStatus, revisitDay, that.holidays)
          confidant = $('#confidant').val() ? $('#confidant').val() : ""
          confidant = confidant == "その他" ? $('#other_confidant_name').val() : confidant
        }

        let messageTemplate = function(ver) {
          ver = ver? `[${ver}]` + "\n": ""

          let date = `${that.ReceiveOrderObj.data.next_action_date}`
          
          return `${ver}案件ID：${that.ReceiveOrderObj.data.subject_id}
案件名：${that.ReceiveOrderObj.data.subject_name}${apSlackId}${retiremented}${passerSlackId}
回答種別：${that.ReceiveOrderObj.data.orders_type}${evaluation}${isRevisitText}${revisitDayText}
次回アクション日：${date}
理由：${that.ReceiveOrderObj.data.reasons}
備考：${that.ReceiveOrderObj.data.other_things}`
        }

        let checkPostFlg = window.confirm('下記内容で間違いないですか？必ず確認してください。\n\n' + messageTemplate())
      
        if(checkPostFlg) {
  
          //[投稿画面バージョン]
          let Extension_version = that.store.state.version;

          // talknote投稿
          (async function() {

            let postID = func.getPostID(that.ReceiveOrderObj.data.subject_id, that.slackChannel.userID, that.slackChannel.id)
            that.ReceiveOrderObj.data.post_id = postID

            let toSlack = messageTemplate(Extension_version) + 
            `
-----------------------------
POST ID：${postID}
こちらのIDは投稿画面からの投稿IDです。`

            that.ReceiveOrderObj.data.message_textarea = func.textEscape(toSlack)

            const requestFunc = new RequestBody.requestBody()
            const groupName = "juchuumaeKaitou"
            const spreadsheetApi = that.store.state.apis[`spreadsheet${that.demo}`]
            const DBInsertApi = that.store.state.apis[`dbInsert${that.demo}`]
            const NowTime = requestFunc.nowTime()
            const Data = that.ReceiveOrderObj.data

            const storeData = that.store.state.userData
            if (storeData.BN !== "" && storeData.BN !== null && storeData.BN !== "NULL" && storeData.BN !== "None") {
              var fullname = storeData.BN + "（" + storeData.firstName + "） " + storeData.lastName
            } else {
              var fullname = storeData.firstName + " " + storeData.lastName
            }
            const belongData = requestFunc.setBelongDate(storeData)

        

            /*********************************************************
           * 投稿開始
           * *******************************************************/
            // Loading表示
            let postResult = true
            that.vueObj.viewLoader('投稿中')
            // slack 投稿者にメンション
            const slackMention = `<@${that.slackChannel.userID}>\n`
            // slack投稿
            const data = {
              "channel": that.slackChannel.id, 
              "username": fullname,
              "text":  slackMention + toSlack, 
              "token": that.slackChannel.token,
              "icon_url": that.slackChannel.iconURL
            }

            const response = await fetch(that.slackChannel.postURL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
            })
            .then(function (response) {
              return response.json()
            }).then(function (response) {
      
              if(response.message == "not current version") {
                alert(`バージョンが違うようです。\n更新します。`);
                location.reload()
                postResult = false
                return false
              } else {
                
                console.log('slack投稿完了', response)
      
                return response
              }
      
            })
            .catch((e) => {
              console.error(e.stack, e.message)
            })         

            const messageURL = `${that.groupURL}/p${response.ts.split('.').join('')}`

            const insertDataRecordCnt = [{
                                        "投稿日時":{"id": "", "val": NowTime},
                                        "全文": {"id": "", "val": Data.message_textarea},
                                        "投稿URL":{"id": "", "val": messageURL},
                                        "ユーザーID":{"id": "", "val": that.ReceiveOrderObj.user_id},
                                        "投稿者名":{"id": "", "val": fullname},
                                        "所属エリア":{"id": "", "val": belongData.userOffice},
                                        "所属（課/部）":{"id": "", "val": belongData.userSection},
                                        "所属（組）":{"id": "", "val": belongData.FS_UserTeam},
                                        "回答種別":{"id": "", "val": Data.orders_type},
                                        "投稿ID":{"id": "", "val": postID},
                                        "案件ID":{"id": "", "val": Data.subject_id},
                                        "案件名":{"id": "", "val": Data.subject_name},
                                        "次回アクション日":{"id": "", "val": Data.next_action_date ? Data.next_action_date : ""},
                                        "開始時間":{"id": "", "val": ""},
                                        "理由":{"id": "", "val": Data.reasons},
                                        "備考":{"id": "", "val": Data.other_things ? Data.other_things : ""},
                                        "所属（組）IS":{"id": "", "val": belongData.IS_UserTeam},
                                        "再訪設定の有無":{"id": "", "val": isRevisit},
                                        "再訪設定日付":{"id": "", "val": revisitDay},
                                        "決済権":{"id": "", "val": $('#settlement_rights_radio_button').val() ? $('#settlement_rights_radio_button').val() : ""},
                                        "タイミング":{"id": "", "val": $("[name='timing']:checked").val() ? $("[name='timing']:checked").val() : ""},
                                        "商談進捗":{"id": "", "val": $('#negotiationStatus').val() ? $('#negotiationStatus').val() : ""},
                                        "費用懸念":{"id": "", "val": $("[name='cost_concern']:checked").val() ? $("[name='cost_concern']:checked").val() : ""},
                                        "課題抽出":{"id": "", "val": $("[name='issue_extraction']:checked").val() ? $("[name='issue_extraction']:checked").val() : ""},
                                        "課題":{"id": "", "val": $('#issue').val() ? $('#issue').val() : ""},
                                        "決済方法":{"id": "", "val": $("[name='settlement_method']:checked").val() ? $("[name='settlement_method']:checked").val() : ""},
                                        "希望決済方法":{"id": "", "val": $("[name='wish_settlement_method']:checked").val() ? $("[name='wish_settlement_method']:checked").val() : ""},
                                        "相談":{"id": "", "val": $('#consultation_pulldown').val() ? $('#consultation_pulldown').val() : ""},
                                        "相談相手":{"id": "", "val": confidant},
                                        "追客評価":{"id": "", "val": evaluation},
                                        }]

            let sheetData = requestFunc.createBody(groupName, that.isProd, insertDataRecordCnt)

            const next_action_date = Data.next_action_date.split(" ")[0].replace("/", "-").replace("/", "-")
            const orders_type = Data.orders_type == "追客延長" ? Data.orders_type + evaluation : Data.orders_type

            let dbData_order_pre_responses = {
              "table_name": "order_pre_responses",
              "form": {
                "non_sub_query": {
                  "subjects_id": Data.subject_id,
                  "post_id": postID,
                  "users_id": parseInt(storeData.id),
                  "created_at": NowTime,
                },
                "sub_query": {
                  "users": false,
                  "responses_type": "SELECT id FROM order_pre_responses_statuses WHERE name = '" + orders_type + "'",
                }
              },
              "authC": {
                "password": "W!z|a~.x.T~Tzca8CBCm",
                "mail": "system@sungrove.co.jp"
              },
              "support_data": {
                "users_id": that.store.state.userData.id,
                "is_prod": sheetData.support_data.is_prod
              }
            }

            if (next_action_date) {
              dbData_order_pre_responses.form.non_sub_query.next_action_date = next_action_date
            }

            if (storeData.sections_id && storeData.sections_id !== "NULL" && storeData.sections_id !== "None") {
              dbData_order_pre_responses.form.non_sub_query.sections_id = parseInt(storeData.sections_id)
            }

            if (storeData.teams_id && storeData.teams_id !== "NULL" && storeData.teams_id !== "None") {
              dbData_order_pre_responses.form.non_sub_query.teams_id = parseInt(storeData.teams_id)
            }

            let teams_ids = ""
            if (storeData.teams_id && storeData.teams_id != "NULL" && storeData.teams_id != "None") {
              teams_ids = storeData.teams_id.split(",")
            } else {
              teams_ids = [""]
            }

            let dbData_order_pre_responses_group_teams = []
            for (let i = 0; i < teams_ids.length ; i++) {
              dbData_order_pre_responses_group_teams[i] = {
                "table_name": "order_pre_responses_group_teams",
                "form": {
                  "non_sub_query": {
                    "post_id": postID,
                    "created_at": NowTime
                  },
                  "sub_query": {
                    "users": false,
                  }
                },
                "support_data": {
                  "users_id": that.store.state.userData.id,
                  "is_prod": sheetData.support_data.is_prod
                }
              }
            }

            let dbData_array = []
            dbData_array.push(dbData_order_pre_responses)

            for (let i = 0; i < teams_ids.length ; i++) {
              if (storeData.teams_id && storeData.teams_id != "NULL" && storeData.teams_id != "None") {
                dbData_order_pre_responses_group_teams[i].form.non_sub_query.teams_id = parseInt(teams_ids[i])
              }
              dbData_array.push(dbData_order_pre_responses_group_teams[i])
            }

            if(!postResult) {
              return false
            }

            that.$btn.addClass('disabled').removeClass('pulse')

            //スプレッドシート登録へ送信   
            const spreadRequest = requestFunc.apiRequestExecution(spreadsheetApi, sheetData, that.key)
            const DBRequest = requestFunc.apiRequestExecution(DBInsertApi, dbData_array, that.key)
            Promise.all([spreadRequest, DBRequest])
            .then(function (response) {
              console.log(response)
              // Loading非表示
              that.vueObj.viewLoader()
            })
            .catch((e) => {
              console.error(e.stack, e.message)
            })

            
            // 完了画面表示
            const params = {
              groupTitle: that.groupTitle,
              groupURL: that.groupURL,
              searchURL: messageURL,
              calURL: '',
              message: '',
              completeTitle: '投稿完了'
            }
            that.vueObj.viewComplete(params)

          })()
        }
      }
    })
  }
}

const formFunction = new formFuncClass.formFunctions(moment, $, sha256)

import RequestBody from "./requestBody.class"
import OfficeDatas  from "./officeData"
import formHoliday from './formholiday.class'
import $ from 'jquery'
import moment from "moment"
import sha256 from 'crypto-js/sha256'
import formFuncClass  from "./formFunctions.class"

export default {
  juchuumaeKaitou
}